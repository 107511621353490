'use client';
import { FC } from 'react';
import {
  cvaButton,
  cvaButtons,
  cvaDescr,
  cvaIcon,
  cvaRoot,
  cvaTitle,
} from './CityDefaultStyles';
import IconPin from '/public/icons/pin2.svg';
import { Button } from '@/shared/ui/Button';
import { useHeaderStore } from '@/shared/store/headerStore';

interface Props {}

const CityDefault: FC<Props> = () => {
  const setShowChooseCity = useHeaderStore(
    (state) => state.setShowChooseCity
  );
  const setShowCityDefault = useHeaderStore(
    (state) => state.setShowCityDefault
  );

  const handleClickAccept = () => {
    setShowCityDefault(false);
    localStorage.setItem(
      'showCityDefault',
      'false'
    );
  };

  const handleClickOther = () => {
    setShowCityDefault(false);
    localStorage.setItem(
      'showCityDefault',
      'false'
    );
    setShowChooseCity(true);
  };

  return (
    <div className={cvaRoot()}>
      <IconPin className={cvaIcon()} />
      <div className={cvaTitle()}>Челябинск</div>
      <div className={cvaDescr()}>
        Это ваш город?
      </div>
      <div className={cvaButtons()}>
        <Button
          className={cvaButton()}
          size={'small'}
          color={'mediumGreen'}
          hoverEffect={'highlight'}
          onClick={() => handleClickAccept()}
        >
          Да, верно
        </Button>
        <Button
          className={cvaButton()}
          size={'small'}
          color={'mediumGreyOnWhite'}
          hoverEffect={'highlight'}
          onClick={() => handleClickOther()}
        >
          Нет, другой
        </Button>
      </div>
    </div>
  );
};

export default CityDefault;
