import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'DropzoneFiles2Styles-cvaRoot',
  'relative overflow-hidden',
  'flex flex-col-reverse sm:flex-row items-start justify-between gap-0.8 sm:gap-2.4',
]);

export const cvaLeft = cva([
  'DropzoneFiles2Styles-cvaLeft',
]);

export const cvaRight = cva([
  'DropzoneFiles2Styles-cvaRight',
  'relative overflow-hidden',
  'flex-shrink',
]);

export const cvaDrag = cva([
  'DropzoneFiles2Styles-cvaDrag',
  'relative',
  'bg-cMediumGreyOnWhite',
  'p-[1.4rem_2rem_1.4rem_4.4rem] sm:p-[1.5rem_1.6rem_1.7rem_4.8rem]',
  'rounded-[1.2rem] sm:rounded-[1.6rem]',
  'cursor-pointer',
]);

export const cvaInput = cva([
  'DropzoneFiles2Styles-cvaInput',
  'hidden',
]);

export const cvaDragIcon = cva([
  'DropzoneFiles2Styles-cvaDragIcon',
  'absolute left-1.6 top-1.4 sm:top-1.5',
  'w-2 h-2 sm:w-2.4 sm:h-2.4',
  'fill-cBlack',
]);

export const cvaDragPlaceholder = cva([
  'DropzoneFiles2Styles-cvaDragPlaceholder',
  'text-bodyMSemibold whitespace-nowrap',
]);

export const cvaSelectedFiles = cva([
  'DropzoneFiles2Styles-cvaSelectedFiles',
  'py-1.6',
]);

export const cvaSelectedFile = cva([
  'DropzoneFiles2Styles-cvaSelectedFile',
  'flex items-center gap-0.2 sm:gap-0.8',
]);

export const cvaSelectedFileName = cva([
  'DropzoneFiles2Styles-cvaSelectedFileName',
  'text-bodyLSemibold sm:text-bodyMSemibold text-ellipsis overflow-hidden whitespace-nowrap',
]);

export const cvaDelete = cva([
  'DropzoneFiles2Styles-cvaDelete',
  'inline-block',
  'flex items-center justify-center',
  '-ml-0.4 w-2.4 h-2.4 sm:ml-0 sm:w-2.4 sm:h-2.4',
  'cursor-pointer select-none',
]);

export const cvaDeleteIcon = cva([
  'DropzoneFiles2Styles-cvaDeleteIcon',
  'w-2.4 h-2.4 sm:w-2.4 sm:h-2.4',
  'fill-cBlack',
]);
