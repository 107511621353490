'use client';
import { FC, ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props {
  className?: string;
  headerWrapperClassName?: string;
  bodyWrapperClassName?: string;
  bodyWrapperInnerClassName?: string;
  headerSlot?: ReactNode;
  bodySlot?: ReactNode;
  isOpen: boolean;
  onChange: () => void;
}

export const Accordion: FC<Props> = ({
  className,
  headerWrapperClassName,
  bodyWrapperClassName,
  bodyWrapperInnerClassName,
  headerSlot,
  bodySlot,
  isOpen,
  onChange,
}) => {
  return (
    <div
      className={twMergeExt(cvaRoot(), className)}
    >
      <motion.div
        className={headerWrapperClassName}
        onClick={onChange}
      >
        {headerSlot}
      </motion.div>
      <motion.div
        className={twMergeExt(
          cvaBodyWrapper(),
          bodyWrapperClassName
        )}
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{
          ease: 'easeOut',
          duration: 0.3,
        }}
      >
        <div
          className={twMergeExt(
            cvaBodyWrapperInner(),
            bodyWrapperInnerClassName
          )}
        >
          {bodySlot}
        </div>
      </motion.div>
    </div>
  );
};

const cvaRoot = cva([
  'Accordion-cvaRoot',
  'flex flex-col',
]);

const cvaBodyWrapper = cva([
  'Accordion-cvaBodyWrapper',
  'overflow-hidden',
]);

const cvaBodyWrapperInner = cva([
  'Accordion-cvaBodyWrapperInner',
  'pt-1.2 sm:pt-1.6',
]);
