import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'CityDefaultStyles-cvaRoot',
  'fixed z-[20] left-2.4 right-2.4 sm:right-auto bottom-3',
  'w-auto sm:w-[41.6rem]',
  'p-2.4',
  'bg-cWhite',
  'text-center',
  'rounded-[2.4rem]',
  '[box-shadow:0_0_5.6rem_0_rgba(33,61,56,0.08)]',
]);

export const cvaIcon = cva([
  'CityDefaultStyles-cvaIcon',
  'block',
  'mx-auto mb-1.2',
  'w-2.4 h-2.4',
  'fill-cBlack',
]);

export const cvaTitle = cva([
  'CityDefaultStyles-cvaTitle',
  'mb-1.2',
  'text-h3 font-secondary',
]);

export const cvaDescr = cva([
  'CityDefaultStyles-cvaDescr',
  'mb-2.4',
  'text-bodyM text-cBlack64',
]);

export const cvaButtons = cva([
  'CityDefaultStyles-cvaButtons',
  'inline-flex items-center gap-0.8',
]);

export const cvaButton = cva([
  'CityDefaultStyles-cvaButton',
  'px-2.4',
]);
