export const wrapSubstringWithSpan = (
  string: string,
  substring: string
): string => {
  if (!substring) return string;

  const regex = new RegExp(
    `(${substring})`,
    'gi'
  );
  return string.replace(
    regex,
    '<span class="group-hover:text-cMediumGreen transition-colors duration-300">$1</span>'
  );
};
