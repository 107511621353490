import { API } from '../api';
import { axiosInstance } from '../axios';
import { ISearchHistoryData } from './types';

export const getSearchHistory = async () => {
  try {
    const result =
      await axiosInstance.get<ISearchHistoryData>(
        API.getSearchHistory
      );
    return result.data?.data;
  } catch (e: any) {
    // console.error(e);
  }
};
