const phoneValidation = (value: string) => {
  const re = new RegExp(
    '^\\+7\\s\\(\\d\\d\\d\\)\\s\\d\\d\\d\\s\\d\\d\\s\\d\\d+$',
    'i'
  );

  if (!re.test(value)) {
    return false;
  } else {
    return value;
  }
};

const phoneValidationEmpty = (value: string) => {
  if (!value || value === `+7 (___) ___ __ __`) {
    return false;
  } else {
    return value;
  }
};

export const validation: any = {
  phone: phoneValidation,
  phoneEmpty: phoneValidationEmpty,
};
