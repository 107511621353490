'use client';
import { Link } from '@/shared/ui/Link';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { LinksAccordion } from '@/widgets/footer/ui/LinksAccordion';
import {
  cvaNavLink,
  cvaNavLinkList,
} from '@/widgets/footer/ui/styles';
import SlideAnimation from '@/widgets/SlideAnimation/SlideAnimation';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import FireIcon from '~/icons/fire.svg';
import FlowerIcon from '~/icons/flower.svg';

interface Props {
  className?: string;
}

export const Nav: FC<Props> = ({ className }) => {
  return (
    <nav
      className={twMergeExt(cvaRoot(), className)}
    >
      <style jsx>{`
        @media (max-width: 767px) {
          .dynamicGrid {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr;
            // grid-template-columns: repeat(
            //   2,
            //   15.2rem
            // );
            grid-template-rows: repeat(
              ${4 / 2},
              min-content
            );
            grid-column: 1 / span 2;
            width: 100%;
            gap: 0.8rem;
          }
        }
      `}</style>
      <div
        className={twMergeExt(
          cvaNavLinkList(),
          'order-3 sm:order-1 dynamicGrid'
        )}
      >
        <Link
          contentWrapperClassName={cvaNavLink({
            highlighted: true,
          })}
          hoverEffect={'color'}
          href={
            process.env
              .NEXT_PUBLIC_FRONT_BASE_URL +
            '/brands/'
          }
          prefetch={false}
        >
          <SlideAnimation>Бренды</SlideAnimation>
        </Link>
        {/* <Link
          contentWrapperClassName={cvaNavLink({
            highlighted: true,
          })}
          hoverEffect={'color'}
          href={EMPTY_HREF}
        >
          Компоненты
        </Link> */}
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: true,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/sale/'
            }
            prefetch={false}
          >
            Акции
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: true,
            })}
            hoverEffect={'color'}
            href={'/products/new/'}
          >
            Новинки
            <FlowerIcon
              className={twMergeExt(
                cvaNavLinkIcon(),
                'text-cMediumGreen'
              )}
            />
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: true,
            })}
            hoverEffect={'color'}
            href={'/products/sale/'}
          >
            Sale
            <FireIcon
              className={twMergeExt(
                cvaNavLinkIcon(),
                'text-cExtraOrange'
              )}
            />
          </Link>
        </SlideAnimation>
        {/* <Link
          contentWrapperClassName={cvaNavLink({
            highlighted: true,
          })}
          hoverEffect={'color'}
          href={EMPTY_HREF}
        >
          Подарочный сертификат
        </Link> */}
      </div>

      <LinksAccordion
        className={twMergeExt(
          'order-1 sm:order-2'
        )}
        title={'Клиентам'}
      >
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/delivery/'
            }
            prefetch={false}
          >
            Доставка
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/payment/'
            }
            prefetch={false}
          >
            Способы оплаты
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/garantiya-na-tovar/'
            }
            prefetch={false}
          >
            Возврат
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/bonus/'
            }
            prefetch={false}
          >
            Бонусная карта
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/authenticity/'
            }
            prefetch={false}
          >
            Гарантия
          </Link>
        </SlideAnimation>
        {/* <Link
          contentWrapperClassName={cvaNavLink({
            highlighted: false,
          })}
          hoverEffect={'color'}
          href={EMPTY_HREF}
        >
          Вопросы-ответы
        </Link> */}
      </LinksAccordion>

      <LinksAccordion
        className={twMergeExt(
          'order-2 sm:order-3'
        )}
        title={'Об ALOEsmart'}
      >
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/contacts/'
            }
            prefetch={false}
          >
            Контакты
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/about/shops/'
            }
            prefetch={false}
          >
            Магазины
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/instagram/'
            }
            prefetch={false}
          >
            Отзывы
          </Link>
        </SlideAnimation>
        <SlideAnimation>
          <Link
            contentWrapperClassName={cvaNavLink({
              highlighted: false,
            })}
            hoverEffect={'color'}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/news/'
            }
            prefetch={false}
          >
            Новости
          </Link>
        </SlideAnimation>
      </LinksAccordion>
    </nav>
  );
};

const cvaRoot = cva([
  'Nav-cvaRoot',
  'grid grid-cols-2 gap-0.8 sm:flex sm:justify-between sm:gap-0 lg:justify-start lg:gap-[10.6rem]',
  'lg:pr-6.2',
]);

const cvaNavLinkIcon = cva([
  'Footer-cvaNavLinkIcon',
  'w-1.6 h-1.6 shrink-0 grow-0',
]);
