import { cva } from 'class-variance-authority';

export const cvaNavLink = cva(
  ['Footer-cvaLink', 'flex items-center gap-0.4'],
  {
    variants: {
      highlighted: {
        true: 'text-cBlack text-bodyLSemibold hover:text-cMediumGreen transition-all duration-300',
        false:
          'text-cBlack64 text-bodyM hover:text-cMediumGreen transition-all duration-300',
      },
      disabled: {
        true: 'cursor-auto hover:text-current',
        false: '',
      },
    },
  }
);

export const cvaNavLinkList = cva([
  'Nav-cvaNavLinkList',
  'sm:inline-flex sm:justify-start sm:flex-col gap-1.2 sm:gap-1.6',
  'w-[15.2rem] sm:w-[18.7rem] lg:w-[12.8rem]',
]);
