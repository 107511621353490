import { LONG_REVALIDATE } from '@/shared/constants';
import { API } from '../api';
import { axiosInstance } from '../axios';
import { ISearchRecommendProductsData } from './types';

export const getSearchRecommendProducts =
  async () => {
    try {
      const result =
        await axiosInstance.get<ISearchRecommendProductsData>(
          API.getSearchRecommendProducts,
          { cache: { ttl: LONG_REVALIDATE } }
        );
      return result.data?.data;
    } catch (e: any) {
      // console.error(e);
    }
  };
