import { API } from '../api';
import { axiosInstance } from '../axios';
import { IOftenSearchedData } from './types';

export const getSearchOftenSearched =
  async () => {
    try {
      const result =
        await axiosInstance.get<IOftenSearchedData>(
          API.getSearchOftenSearched
        );
      return result.data?.data;
    } catch (e: any) {
      // console.error(e);
    }
  };
