'use client';
import { IMenuItem } from '@/shared/api/getMenu/types';
import { Button } from '@/shared/ui/Button';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { usePathname } from 'next/navigation';
import {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import CloseIcon from '~/icons/btn_close.svg';
import HeartIcon from '~/icons/heart.svg';
import { MenuCatalogPopup } from './MenuCatalogPopup';

interface Props {
  className?: string;
  menu: IMenuItem[];
}

export const MenuCatalogButton = forwardRef<
  HTMLButtonElement,
  Props
>(({ className, menu }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <Button
        ref={ref}
        className={twMergeExt(
          cvaRoot(),
          className
        )}
        contentWrapperClassName={cvaCatalogButtonWrapper()}
        color={
          isOpen ? 'lightGreen' : 'mediumGreen'
        }
        size={'small'}
        hoverEffect={'highlight'}
        onClick={() => setIsOpen((prev) => !prev)}
        disableInViewAnimation={true}
      >
        Каталог
        {isOpen ? (
          <CloseIcon
            className={'w-1.6 h-1.6 text-cWhite'}
          />
        ) : (
          <HeartIcon
            className={'w-1.6 h-1.6 text-cWhite'}
          />
        )}
      </Button>

      <AnimatePresence>
        {isOpen ? (
          <Portal>
            <MenuCatalogPopup
              onClose={() => setIsOpen(false)}
              menu={menu}
            />
          </Portal>
        ) : null}
      </AnimatePresence>
    </>
  );
});

MenuCatalogButton.displayName =
  'MenuCatalogButton';

const cvaRoot = cva([
  'MenuCatalogButton-cvaRoot',
  'hidden md:inline-flex',
  'px-1.6',
]);

const cvaCatalogButtonWrapper = cva([
  'HeaderBottom-cvaCatalogButtonWrapper',
  'flex items-center gap-0.4',
  'text-bodyMSemibold',
]);
