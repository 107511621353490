'use client';
import { postFormSubmit } from '@/shared/api/postFormSubmit';
import { Button } from '@/shared/ui/Button';
import { cvaError } from '@/shared/ui/Input/InputStyles';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { object, string } from 'yup';
import ArrowRightIcon from '~/icons/arrow-right-2.svg';
import SuccessIcon from '~/icons/subscribe_success.svg';

interface Props {
  className?: string;
}

export const NewsSubscriptionForm: FC<Props> = ({
  className,
}) => {
  const [isFocused, setIsFocused] =
    useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => setIsFocused(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] =
    useState(false);

  const initialValues = {
    email: '',
  };

  const userSchema = object({
    email: string()
      .email('Неправильный формат')
      .required('Обязательно для заполнения'),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('form_id', 'subscribe');
      formData.append(
        'fields[email]',
        values.email
      );

      await postFormSubmit(formData);
      setSuccess(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userSchema}
    >
      {({ getFieldProps, touched, errors }) => (
        <Form
          className={twMergeExt(
            cvaRoot(),
            className
          )}
          autoComplete="off"
        >
          <h4 className={cvaHeading()}>
            Советы по уходу за кожей,
            новости и специальные предложения
          </h4>

          {success ? (
            <div className={cvaSuccess()}>
              <SuccessIcon />
              <p className={cvaSuccessText()}>
                Подписка успешно оформлена
              </p>
            </div>
          ) : (
            <div
              className={cvaInputContainer({
                hasError: Boolean(
                  touched.email && errors.email
                ),
                isFocused,
              })}
            >
              <Field
                {...getFieldProps('email')}
                as={'input'}
                placeholder={'E-mail'}
                className={cvaInput()}
                onBlur={() => onBlur()}
                onFocus={() => onFocus()}
              />

              <Button
                className={cvaButton()}
                type={'submit'}
                isLoading={isLoading}
                color={'iconOnWhite'}
                rootWrapperClassName="flex items-center"
              >
                <ArrowRightIcon
                  className={cvaButtonIcon()}
                />
              </Button>
            </div>
          )}
          {touched.email && errors.email ? (
            <div
              className={cvaError({ isFocused })}
            >
              {errors.email}
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

const cvaRoot = cva([
  'NewsSubscriptionForm-cvaRoot',
]);

const cvaHeading = cva([
  'NewsSubscriptionForm-cvaHeading',
  'font-secondary text-[1.6rem] leading-[1.24em] font-bold sm:text-h4Bold',
]);

const cvaInputContainer = cva(
  [
    'NewsSubscriptionForm-cvaInputContainer',
    'flex overflow-hidden',
    'mt-2.4 px-2 sm:px-2.4 rounded-[1.6rem]',
    'bg-cWhite border-2 border-transparent',
    'transition-all duration-300',
    'group hover:bg-cMediumGreyOnWhite',
  ],
  {
    variants: {
      hasError: {
        true: '!border-[#FF5E7F]',
        false: '',
      },
      isFocused: {
        true: ['!border-cMediumGreen'],
        false: '',
      },
    },
  }
);

const cvaInput = cva([
  'NewsSubscriptionForm-cvaInput',
  'w-full',
  'py-1.3 sm:py-[1.45rem] border border-transparent outline-none',
  'text-bodyLSemibold',
  'placeholder:text-cBlack placeholder:transition-all placeholder:duration-300',
  'group-hover:placeholder:text-cMediumGreen group-hover:bg-cMediumGreyOnWhite transition-all duration-300',
]);

const cvaButton = cva([
  'NewsSubscriptionForm-cvaButton',
  'flex justify-end items-center',
  'flex-shrink-0 w-fit',
]);

const cvaButtonIcon = cva([
  'NewsSubscriptionForm-cvaButtonIcon',
  'w-2.4 h-2.4',
  'group-hover:text-cMediumGreen hover:text-cMediumGreen',
  'transition-colors duration-300',
]);

const cvaSuccess = cva([
  'NewsSubscriptionForm-cvaSuccess',
  'flex items-center gap-0.8',
  'mt-3.2',
]);

const cvaSuccessText = cva([
  'NewsSubscriptionForm-cvaSuccessText',
  'text-[#213D38] font-semibold text-bodyLSemibold lg:text-[1.8rem]',
]);
