import { Link } from '@/shared/ui/Link';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock-upgrade';
import { FC, useEffect, useRef } from 'react';
import TelegramIcon from '~/icons/tg.svg';
import WaIcon from '~/icons/whats-app.svg';

import { motion } from 'framer-motion';

import {
  cvaClose,
  cvaCloseIcon,
  cvaInner,
  cvaOverlay,
  cvaRoot,
} from '@/widgets/CallbackPopup/ui/CallbackPopupStyles';

import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import IconClose from '/public/icons/close.svg';

interface Props {
  onClose: () => void;
  onShowCallbackPopup: () => void;
}

const PhonePopupMobile: FC<Props> = ({
  onClose,
  onShowCallbackPopup,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const handleClickCallback = () => {
    onShowCallbackPopup();
    onClose();
  };

  useEffect(() => {
    if (innerRef.current) {
      disableBodyScroll(innerRef.current, {
        reserveScrollBarGap: true,
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      className={twMergeExt(cvaRoot())}
      data-lenis-prevent
    >
      <div
        className={cvaOverlay()}
        onClick={() => onClose()}
      >
        <button
          type="button"
          className={cvaClose()}
          onClick={() => onClose()}
        >
          <IconClose className={cvaCloseIcon()} />
        </button>
        <div
          className={cvaInner()}
          ref={innerRef}
        >
          <div className="flex flex-col px-2.4 py-3.2">
            <div className={cvaLabel()}>
              Горячая линия
            </div>
            <Link
              className={cvaLink()}
              href={'tel:8 (800) 222-06-40'}
              hoverEffect={'color'}
            >
              8 (800) 222-06-40
            </Link>
            <div
              className={twMergeExt(
                cvaLabel(),
                'mt-0.4'
              )}
            >
              единый 07:00 - 20:00
            </div>
            <div className="mt-1.6">
              <div className={cvaLabel()}>
                Общие вопросы
              </div>
              <Link
                className={cvaLink()}
                href={'mailto:info@aloesmart.ru'}
                hoverEffect={'color'}
              >
                info@aloesmart.ru
              </Link>
            </div>
            <div className="mt-1.6">
              <div className={cvaLabel()}>
                Связаться с директором
              </div>
              <Link
                className={cvaLink()}
                href={'tel:+7 (900) 094-49-70'}
                hoverEffect={'color'}
              >
                +7 (900) 094-49-70
              </Link>
              <Button
                className={
                  'text-bodyMSemibold w-full mt-2.4'
                }
                rootWrapperClassName="w-full"
                animateWrapperClassName="w-full"
                size={'medium'}
                color={'mediumGreen'}
                hoverEffect={'highlight'}
                onClick={() =>
                  handleClickCallback()
                }
              >
                Обратная связь
              </Button>
              <div className="mt-3.2">
                <div className={cvaLabel()}>
                  Мессенджеры
                </div>
                <div
                  className={twMergeExt(
                    cvaMessengers(),
                    'mt-1.6'
                  )}
                >
                  <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    size={'small'}
                    hoverEffect={'highlight'}
                    rootWrapperClassName="w-full [&:not(:last-child)]:border-b [&:not(:last-child)]:border-cBlack16"
                    animateWrapperClassName="w-full"
                    href={
                      'https://t.me/ALOEsmart'
                    }
                  >
                    <TelegramIcon
                      className={cvaMessengerIcon()}
                    />
                    Telegram
                  </Link>
                  <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    rootWrapperClassName="w-full"
                    animateWrapperClassName="w-full"
                    size={'small'}
                    hoverEffect={'highlight'}
                    href={
                      'https://api.whatsapp.com/send?phone=79000944970'
                    }
                  >
                    <WaIcon
                      className={cvaMessengerIcon()}
                    />
                    WhatsApp
                  </Link>
                  {/* <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    size={'small'}
                    hoverEffect={'highlight'}
                    href={
                      'viber://chat?number=%2B79000944970'
                    }
                  >
                    <ViberIcon
                      className={cvaMessengerIcon()}
                    />
                    Viber
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PhonePopupMobile;

const cvaLabel = cva([
  'PhonesPopup-cvaLabel',
  'text-cBlack48 text-bodyM',
]);

const cvaLink = cva([
  'PhonesPopup-cvaLink',
  'inline-flex',
  'mt-0.4',
  'text-bodyMSemibold',
]);

const cvaMessengers = cva([
  'PhonesPopup-cvaMessengers',
  ' flex flex-col',
  ' overflow-hidden',
  ' mt-1.6 rounded-[1.6rem]',
  ' text-bodyMSemibold',
]);

const cvaMessenger = cva([
  'PhonesPopup-cvaMessenger',
  'justify-start',
  'px-1.6 !rounded-none',
  'w-full h-full',
  '[&:not(:last-child)]:border-b [&:not(:last-child)]:border-cBlack16',
]);

const cvaMessengerContentWrapper = cva([
  'PhonesPopup-cvaMessengerContentWrapper',
  'flex justify-start gap-0.8',
]);

const cvaMessengerIcon = cva([
  'PhonesPopup-cvaMessengerIcon',
  'w-2.4 h-2.4',
]);
