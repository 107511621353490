'use client';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Popup } from '@/shared/ui/Popup';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { FC, useRef } from 'react';
import { useScrollbarWidth } from 'react-use';
import TelegramIcon from '~/icons/tg.svg';
import WaIcon from '~/icons/whats-app.svg';

interface Props {
  onClose: () => void;
  onShowCallbackPopup: () => void;
}

export const PhonesPopup: FC<Props> = ({
  onClose,
  onShowCallbackPopup,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollbarWidth = useScrollbarWidth();

  useOnClickOutside(ref, onClose, [
    '.PhonesButton-cvaRoot',
  ]);

  const handleClickCallback = () => {
    onShowCallbackPopup();
    onClose();
  };

  return (
    <>
      <Popup
        overlayClassName={'hidden'}
        scrollClassName={cvaScroll()}
        popupClassName={cvaPopup()}
        closeButtonClassName={'hidden'}
        closeOnClickOutside={false}
        onClose={onClose}
      >
        <div
          style={{ paddingRight: scrollbarWidth }}
        >
          <Container
            className={cvaContainer()}
            variant={'small'}
          >
            <motion.div
              ref={ref}
              className={cvaBody()}
              initial={{
                y: '-1.2rem',
                opacity: 0,
              }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: '-1.2rem', opacity: 0 }}
              transition={{
                duration: 0.3,
                ease: 'easeOut',
              }}
            >
              <div>
                <div className={cvaLabel()}>
                  Горячая линия
                </div>
                <Link
                  className={cvaLink()}
                  href={'tel:8 (800) 222-06-40'}
                  hoverEffect={'color'}
                >
                  8 (800) 222-06-40
                </Link>
                <div
                  className={twMergeExt(
                    cvaLabel(),
                    'mt-0.4'
                  )}
                >
                  единый 07:00 – 20:00
                </div>
              </div>
              <div>
                <div className={cvaLabel()}>
                  Общие вопросы
                </div>
                <Link
                  className={cvaLink()}
                  href={
                    'mailto:info@aloesmart.ru'
                  }
                  hoverEffect={'color'}
                >
                  info@aloesmart.ru
                </Link>
              </div>
              <div>
                <div className={cvaLabel()}>
                  Связаться с директором
                </div>
                <Link
                  className={cvaLink()}
                  href={'tel:+7 (900) 094-49-70'}
                  hoverEffect={'color'}
                >
                  +7 (900) 094-49-70
                </Link>
              </div>

              <Button
                className={
                  'text-bodyMSemibold w-full'
                }
                rootWrapperClassName="w-full"
                animateWrapperClassName="w-full"
                size={'medium'}
                color={'mediumGreen'}
                hoverEffect={'highlight'}
                onClick={() =>
                  handleClickCallback()
                }
              >
                Обратная связь
              </Button>
              <div>
                <div className={cvaLabel()}>
                  Мессенджеры
                </div>
                <div className={cvaMessengers()}>
                  <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    size={'small'}
                    hoverEffect={'highlight'}
                    rootWrapperClassName="w-full [&:not(:last-child)]:border-b [&:not(:last-child)]:border-cBlack16"
                    animateWrapperClassName="w-full"
                    href={
                      'https://t.me/ALOEsmart'
                    }
                  >
                    <TelegramIcon
                      className={cvaMessengerIcon()}
                    />
                    Telegram
                  </Link>
                  <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    rootWrapperClassName="w-full [&:not(:last-child)]:border-b [&:not(:last-child)]:border-cBlack16"
                    animateWrapperClassName="w-full"
                    size={'small'}
                    hoverEffect={'highlight'}
                    href={
                      'https://api.whatsapp.com/send?phone=79000944970'
                    }
                  >
                    <WaIcon
                      className={cvaMessengerIcon()}
                    />
                    WhatsApp
                  </Link>
                  {/* <Link
                    className={cvaMessenger()}
                    contentWrapperClassName={cvaMessengerContentWrapper()}
                    color={'lightGrey'}
                    size={'small'}
                    hoverEffect={'highlight'}
                    href={
                      'viber://chat?number=%2B79000944970'
                    }
                  >
                    <ViberIcon
                      className={cvaMessengerIcon()}
                    />
                    Viber
                  </Link> */}
                </div>
              </div>
            </motion.div>
          </Container>
        </div>
      </Popup>
    </>
  );
};

const cvaScroll = cva([
  'PhonesPopup-cvaScroll',
  'p-0',
]);

const cvaPopup = cva([
  'PhonesPopup-cvaPopup',
  'align-top',
  'w-full',
  'bg-transparent',
]);

const cvaContainer = cva([
  'PhonesPopup-cvaContainer',
  'flex justify-end',
]);

const cvaBody = cva([
  'PhonesPopup-cvaBody',
  'relative top-3.1',
  'flex flex-col gap-2.4',
  'w-[30rem]',
  'p-2.4 rounded-[1.6rem]',
  'shadow-popup bg-cWhite',
]);

const cvaLabel = cva([
  'PhonesPopup-cvaLabel',
  'text-cBlack48 text-bodyM',
]);

const cvaLink = cva([
  'PhonesPopup-cvaLink',
  'inline-flex',
  'mt-0.4',
  'text-bodyMSemibold',
]);

const cvaMessengers = cva([
  'PhonesPopup-cvaMessengers',
  ' flex flex-col',
  ' overflow-hidden',
  ' mt-1.6 rounded-[1.6rem]',
  ' text-bodyMSemibold',
]);

const cvaMessenger = cva([
  'PhonesPopup-cvaMessenger',
  'justify-start',
  'px-1.6 !rounded-none',
  'w-full h-full',
  '[&:not(:last-child)]:border-b [&:not(:last-child)]:border-cBlack16',
]);

const cvaMessengerContentWrapper = cva([
  'PhonesPopup-cvaMessengerContentWrapper',
  'flex justify-start gap-0.8',
]);

const cvaMessengerIcon = cva([
  'PhonesPopup-cvaMessengerIcon',
  'w-2.4 h-2.4',
]);
