'use client';

import {
  usePathname,
  useSearchParams,
} from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
  interface Window {
    ym: (
      id: number,
      method: string,
      ...args: any[]
    ) => void;
  }
}

const METRIKA_ID =
  process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID;

export function Metrika() {
  const pathName = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (METRIKA_ID) {
      window.ym(
        Number(METRIKA_ID),
        'hit',
        window.location.href
      );
    }
  }, [pathName, searchParams]);

  if (!METRIKA_ID) {
    return null;
  }

  return (
    <Script id="yandex-metrika">
      {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${METRIKA_ID}, "init", {
          defer: true,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true
        });    
      `}
    </Script>
  );
}
