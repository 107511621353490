import { useHeaderHeight } from '@/shared/hooks/useHeaderHeight';
import { Container } from '@/shared/ui/Container';
import { Popup } from '@/shared/ui/Popup';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { FC, ReactNode, useEffect } from 'react';
import { useScrollbarWidth } from 'react-use';
import Bg from '../assets/bg.svg';

interface Props {
  children?: ReactNode;
  onClose: () => void;
  type?: 'mobile' | 'desktop';
}

export const MenuPopup: FC<Props> = ({
  children,
  onClose,
  type = 'desktop',
}) => {
  const headerHeight = useHeaderHeight();
  const scrollbarWidth = useScrollbarWidth();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener(
      'keydown',
      handleKeyDown
    );

    return () => {
      document.removeEventListener(
        'keydown',
        handleKeyDown
      );
    };
  }, []);

  return (
    <Popup
      overlayClassName={cvaOverlay()}
      scrollClassName={cvaScroll()}
      popupClassName={cvaPopup()}
      closeButtonClassName={'hidden'}
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <motion.div
        className={cvaBodyWrapper()}
        initial={{ y: '-100%' }}
        animate={{ y: 0 }}
        exit={{ y: '-100%' }}
        transition={{
          duration: 0.3,
          ease: 'easeOut',
        }}
        style={{
          paddingTop:
            type === 'desktop' ? headerHeight : 0,
          marginRight:
            type === 'desktop'
              ? scrollbarWidth
              : 0,
        }}
      >
        <MotionContainer
          className={
            'relative pointer-events-none'
          }
          variant={'small'}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 0.32,
            transition: { delay: 0.1 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.05 },
          }}
          transition={{
            duration: 0.3,
            ease: 'easeOut',
          }}
          inViewAnimation={false}
        >
          <Bg
            className={
              'absolute -top-[12.138rem] -right-[34.357rem] w-[134.5rem] h-[129.4rem]'
            }
          />
        </MotionContainer>

        <div className={cvaBody({ type: type })}>
          <MotionContainer
            className={
              type === 'mobile' ? '!px-0' : ''
            }
            variant={'small'}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              y: '0rem',
              transition: { delay: 0.2 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.05 },
            }}
            transition={{
              duration: 0.3,
              ease: 'easeOut',
            }}
            inViewAnimation={false}
          >
            {children}
          </MotionContainer>
        </div>
      </motion.div>
    </Popup>
  );
};

const MotionContainer = motion(Container);

const cvaOverlay = cva([
  'MenuPopup-cvaOverlay',
  'z-[97]',
]);

const cvaScroll = cva([
  'MenuPopup-cvaScroll',
  'z-[98]',
  'p-0',
]);

const cvaPopup = cva([
  'MenuPopup-cvaPopup',
  'align-top',
  'w-full',
  'bg-transparent  !opacity-100',
]);

const cvaBodyWrapper = cva([
  'MenuPopup-cvaBodyWrapper',
  'relative',
  'flex flex-col',
  'max-h-screen overflow-hidden',
  'rounded-bl-[6.4rem] rounded-br-[6.4rem]',
  'bg-cWhite',
]);

const cvaBody = cva(
  [
    'MenuPopup-cvaBody',
    'flex-1 overflow-hidden',
    'mb-5.6',
    '',
  ],
  {
    variants: {
      type: {
        mobile: ['pt-0'],
        desktop: ['pt-1.8'],
      },
    },
  }
);
