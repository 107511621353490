import React, {
  useCallback,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import FileIcon from '/public/icons/file.svg';
import {
  cvaDelete,
  cvaDeleteIcon,
  cvaDrag,
  cvaDragIcon,
  cvaDragPlaceholder,
  cvaInput,
  cvaLeft,
  cvaRight,
  cvaRoot,
  cvaSelectedFile,
  cvaSelectedFileName,
  cvaSelectedFiles,
} from './DropzoneFiles2Styles';
import IconClose from '/public/icons/close.svg';

interface IDropzoneFiles2Props {
  onChange: (files: File[]) => void;
  multiple?: boolean;
  placeholder?: string;
  accept?: any;
}

export const DropzoneFiles2 = ({
  onChange,
  placeholder,
  multiple = false,
  accept,
}: IDropzoneFiles2Props) => {
  const [selectedFiles, setSelectedFiles] =
    useState<File[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setSelectedFiles(acceptedFiles);
      onChange(acceptedFiles);
    },
    []
  );

  const { getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      multiple: multiple,
      maxSize: 15242880,
      accept: accept ?? {
        '*/*': [],
      },
    });

  return (
    <div
      className={cvaRoot()}
      data-cursor={'-pointer'}
    >
      <div className={cvaLeft()}>
        <div
          className={cvaDrag()}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            className={cvaInput()}
          />
          <FileIcon className={cvaDragIcon()} />
          <div className={cvaDragPlaceholder()}>
            {/* {selectedFiles.length ? selectedFiles[0].name : <div dangerouslySetInnerHTML={{__html: placeholder ?? ''}}></div>} */}
            <div
              dangerouslySetInnerHTML={{
                __html: placeholder ?? '',
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={cvaRight()}>
        {selectedFiles?.length ? (
          <div className={cvaSelectedFiles()}>
            {selectedFiles.map((file, index) => (
              <div
                className={cvaSelectedFile()}
                key={index}
              >
                <div
                  className={cvaDelete()}
                  onClick={() => {
                    setSelectedFiles((prev) => {
                      const newArr = [...prev];
                      newArr.splice(index, 1);

                      onChange(newArr);

                      return newArr;
                    });
                  }}
                >
                  <IconClose
                    className={cvaDeleteIcon()}
                  />
                </div>
                <div
                  className={cvaSelectedFileName()}
                >
                  {file.name}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
