'use client';
import { PhonesButton } from '@/features/phones-popup';
import { useCityStore } from '@/shared/store/cityStore';
import { useHeaderStore } from '@/shared/store/headerStore';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import ChooseCity from '@/widgets/ChooseCity/ui/ChooseCity';
import CityDefault from '@/widgets/CityDefault/ui/CityDefault';
import CitySuggestionPopup from '@/widgets/CitySuggestionPopup/ui/CitySuggestionPopup';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useState } from 'react';
import BusIcon from '~/icons/bus.svg';
import CityIcon from '~/icons/city.svg';

interface Props {
  // nearestCity: ICity | null;
}

export const HeaderTop: FC<Props> = () => {
  const showChooseCity = useHeaderStore(
    (state) => state.showChooseCity
  );

  const cities = useCityStore(
    (state) => state.cities
  );

  const setShowChooseCity = useHeaderStore(
    (state) => state.setShowChooseCity
  );
  const getShowCityDefaultFromLocalStorage =
    useHeaderStore(
      (state) =>
        state.getShowCityDefaultFromLocalStorage
    );
  const showCityDefault = useHeaderStore(
    (state) => state.showCityDefault
  );
  const cityName = useCityStore(
    (state) => state.cityName
  );
  const cityHasShops = useCityStore(
    (state) => state.cityHasShops
  );

  const handleClickChooseCity = () => {
    setShowChooseCity(true);
  };

  useEffect(() => {
    getShowCityDefaultFromLocalStorage();
  }, []);

  // useEffect(() => {
  //   if (cityName === 'unknown') {
  //     setIsSuggestionCityPopupOpen(true);
  //   }
  // }, [cityName]);

  const [
    isSuggestionCityPopupOpen,
    setIsSuggestionCityPopupOpen,
  ] = useState(true);

  const pathname = usePathname();

  return (
    <>
      <div className={cvaRoot()}>
        <Container
          className={cvaContainer()}
          variant={'small'}
        >
          <div className={cvaInnerContainer()}>
            <div className={cvaButtons()}>
              <Button
                contentWrapperClassName={cvaButton()}
                hoverEffect={'color'}
                onClick={() =>
                  handleClickChooseCity()
                }
                animateWrapperClassName="flex"
              >
                <CityIcon className={cvaIcon()} />
                <span>
                  {cityName === 'unknown'
                    ? 'Выберите город'
                    : cityName}
                </span>
              </Button>
              <Button
                contentWrapperClassName={cvaButton()}
                hoverEffect={'color'}
                href={
                  process.env
                    .NEXT_PUBLIC_FRONT_BASE_URL +
                  '/delivery/'
                }
                prefetch={false}
              >
                <BusIcon className={cvaIcon()} />
                <span>Доставка</span>
              </Button>
            </div>
            {cityHasShops &&
            cityName !== 'Москва' ? (
              <div
                className={twMergeExt(cvaLabel())}
              >
                бесплатно от 3 000 ₽
              </div>
            ) : null}
          </div>

          <div className={cvaInnerContainer()}>
            <div className={cvaLabel()}>
              единый 07:00 – 20:00
            </div>
            <div className={cvaButtons()}>
              <PhonesButton />
            </div>
          </div>
        </Container>
      </div>
      <AnimatePresence>
        {showChooseCity ? (
          <Portal>
            <ChooseCity
              onClose={() =>
                setShowChooseCity(false)
              }
            />
          </Portal>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showCityDefault ? <CityDefault /> : null}
      </AnimatePresence>
      <AnimatePresence>
        {isSuggestionCityPopupOpen &&
        cityName === 'unknown' &&
        cities.length > 0 &&
        pathname === '/' ? (
          <Portal>
            <CitySuggestionPopup
              onClose={() =>
                setIsSuggestionCityPopupOpen(
                  false
                )
              }
              nearestCity={
                cities.find(
                  (city) =>
                    city.name === 'Челябинск'
                ) || cities[0]
              }
              setShowChooseCity={
                setShowChooseCity
              }
            />
          </Portal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

const cvaRoot = cva([
  'HeaderTop-cvaRoot',
  'relative z-[102]',
  'hidden md:block',
  'text-bodyS',
  'bg-cMediumGreyOnWhite',
]);

const cvaContainer = cva([
  'HeaderTop-cvaContainer',
  'flex justify-between',
  'py-0.5',
]);

const cvaInnerContainer = cva([
  'HeaderTop-cvaInnerContainer',
  'flex items-center gap-1.6',
]);

const cvaButtons = cva([
  'HeaderTop-cvaButtons',
  'flex items-center gap-4',
]);

const cvaButton = cva([
  'HeaderTop-cvaButton',
  'flex items-center gap-0.4',
  'whitespace-nowrap',
]);

const cvaLabel = cva([
  'HeaderTop-cvaLabel',
  'text-cBlack64 whitespace-nowrap',
]);

const cvaIcon = cva([
  'HeaderTop-cvaIcon',
  'w-1.6 h-1.6',
  'text-cBlack',
  'transition-all duration-300',
  'group-hover/color:text-cMediumGreen',
]);
