'use client';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  ReactLenis,
  useLenis,
} from 'lenis/react';
import { usePathname } from 'next/navigation';
import {
  ComponentProps,
  forwardRef,
  useEffect,
} from 'react';

interface Props
  extends ComponentProps<typeof ReactLenis> {}

export const SmoothScroll = forwardRef<
  any,
  Props
>(({ children, className, ...props }, ref) => {
  const pathname = usePathname();
  const lenis = useLenis();

  useEffect(() => {
    if (!pathname.includes('/products')) {
      lenis?.scrollTo(0);
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <ReactLenis
      ref={ref}
      className={twMergeExt(
        'lenis-container',
        className
      )}
      {...props}
    >
      {children}
    </ReactLenis>
  );
});

SmoothScroll.displayName = 'SmoothScroll';
