'use client';
import { MenuPopup } from '@/features/menu';
import { MenuLink } from '@/features/menu/ui/MenuLink';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { FC, useEffect, useRef } from 'react';

interface Props {
  onClose: () => void;
}

export const MenuBurgerPopup: FC<Props> = ({
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onClose, [
    '.MenuBurgerButton-cvaRoot',
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener(
      'keydown',
      handleKeyDown
    );

    return () => {
      document.removeEventListener(
        'keydown',
        handleKeyDown
      );
    };
  }, []);

  return (
    <MenuPopup onClose={onClose}>
      <div ref={ref} className={'flex gap-1.2'}>
        {mockData.map((i, index) => (
          <div
            key={index}
            className={
              'flex flex-col basis-[32.3rem] grow-0 shrink'
            }
          >
            <div
              className={
                'py-1.2 text-bodyLSemibold'
              }
            >
              {i.title}
            </div>
            {i.links.map((i, index) => (
              <MenuLink
                prefetch={false}
                key={index}
                href={i.href}
                titleSlot={i.title}
              />
            ))}
          </div>
        ))}
      </div>
    </MenuPopup>
  );
};

const mockData = [
  {
    title: 'Об ALOEsmart',
    links: [
      {
        title: 'Магазины',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/about/shops/',
      },
      {
        title: 'Отзывы',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/instagram/',
      },
      {
        title: 'Новости',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/news/',
      },
      // { title: 'Блог', href: EMPTY_HREF },
      {
        title: 'Контакты',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/contacts/',
      },
    ],
  },
  {
    title: 'Клиентам',
    links: [
      {
        title: 'Доставка',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/delivery/',
      },
      {
        title: 'Способы оплаты',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/payment/',
      },
      {
        title: 'Возврат',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/garantiya-na-tovar/',
      },
      {
        title: 'Бонусная карта',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/bonus/',
      },
      // { title: 'Цена', href: EMPTY_HREF },
      {
        title: 'Гарантия',
        href:
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/authenticity/',
      },
    ],
  },
];
