'use client';
import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import ArrowRightIcon from '~/icons/arrow-right-2.svg';

interface Props {
  className?: string;
}

export const ScrollToTopButton: FC<Props> = ({
  className,
}) => {
  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      className={className}
      contentWrapperClassName={twMergeExt(
        cvaRoot()
      )}
      hoverEffect={'color'}
      onClick={onClick}
    >
      Наверх
      <ArrowRightIcon className={cvaIcon()} />
    </Button>
  );
};

const cvaRoot = cva([
  'ScrollToTopButton-cvaRoot',
  'hidden sm:flex items-center gap-0.8',
  'text-bodyLSemibold',
]);

const cvaIcon = cva([
  'ScrollToTopButton-cvaIcon',
  'w-2.4 h-2.4',
  '-rotate-90',
]);
