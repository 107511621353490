'use client';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';

import { Popup } from '@/shared/ui/Popup';
import { cva } from 'class-variance-authority';
import {
  useMedia,
  useScrollbarWidth,
} from 'react-use';
import { Container } from '@/shared/ui/Container';
import GeoTagIcon from '~/icons/geo_tag_icon.svg';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { useCityStore } from '@/shared/store/cityStore';
import { ICity } from '@/shared/api/getShops/types';

import {
  cvaInner,
  cvaOverlay,
  cvaRoot,
} from '@/widgets/CallbackPopup/ui/CallbackPopupStyles';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock-upgrade';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props {
  onClose: () => void;
  nearestCity: ICity;
  setShowChooseCity?: (value: boolean) => void;
}

const CitySuggestionPopup: FC<Props> = ({
  onClose,
  nearestCity,
  setShowChooseCity,
}) => {
  const scrollbarWidth = useScrollbarWidth();
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia(
    '(max-width: 767px)',
    true
  );

  const setCity = useCityStore(
    (state) => state.setCity
  );

  useOnClickOutside(ref, onClose, [
    '.PhonesButton-cvaRoot',
  ]);

  useEffect(() => {
    if (innerRef.current) {
      disableBodyScroll(innerRef.current, {
        reserveScrollBarGap: true,
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const onSelectCity = () => {
    setCity(nearestCity);
    onClose();
  };

  const onClickNo = () => {
    if (setShowChooseCity) {
      setShowChooseCity(true);
    }
    onClose();
  };

  if (isMobile) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        className={cvaRoot()}
        data-lenis-prevent
      >
        <div
          className={cvaOverlay()}
          onClick={() => onClose}
        ></div>

        <div
          className={twMergeExt(
            cvaInner(),
            'top-[calc(100dvh-30rem)]'
          )}
        >
          <div className="flex flex-col gap-2.4 items-center mt-3.2">
            <div className="flex flex-col items-center gap-1.6">
              <GeoTagIcon className="w-2.4 h-2.4" />
              <h3 className="text-h3 text-cBlack font-secondary">
                {nearestCity.name}
              </h3>
              <span className="text-bodyM text-cBlack/65">
                Это ваш город?
              </span>
            </div>
            <div className="flex gap-0.8">
              <button
                onClick={onSelectCity}
                className="pt-1.3 pb-1.6 px-1.3 rounded-[1.6rem] bg-cMediumGreen text-cWhite transition-all duration-300 hover:bg-cDarkGreen/80 text-bodyMSemibold"
              >
                Да, верно
              </button>
              <button
                onClick={onClickNo}
                className="pt-1.3 pb-1.6 px-1.3 rounded-[1.6rem] bg-cMediumGreyOnWhite text-bodyM text-cBlack transition-all duration-300 hover:bg-cMediumGreyOnWhite/60 text-bodyMSemibold"
              >
                Нет, другой
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <>
      <Popup
        overlayClassName={'hidden'}
        scrollClassName={cvaScroll()}
        popupClassName={cvaPopup()}
        closeButtonClassName={'hidden'}
        closeOnClickOutside={false}
        onClose={onClose}
      >
        <div
          style={{ paddingRight: scrollbarWidth }}
        >
          <Container
            className={twMergeExt(
              cvaContainer(),
              '!pl-2.4'
            )}
            variant={'small'}
          >
            <motion.div
              ref={ref}
              className={cvaBody()}
              initial={{
                y: '-1.2rem',
                opacity: 0,
              }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: '-1.2rem', opacity: 0 }}
              transition={{
                duration: 0.3,
                ease: 'easeOut',
              }}
            >
              <div className="flex flex-col gap-2.4 items-center">
                <div className="flex flex-col items-center gap-1.2 px-3.4">
                  <GeoTagIcon className="w-2.4 h-2.4" />
                  <h3 className="text-h3 text-cBlack font-secondary">
                    {nearestCity.name}
                    {/* Нижний Новгород */}
                  </h3>
                  <span className="text-bodyM text-cBlack/65">
                    Это ваш город?
                  </span>
                </div>
                <div className="flex gap-0.8">
                  <button
                    onClick={onSelectCity}
                    className="pt-1 pb-1.4 px-2.3 rounded-[1.6rem] bg-cMediumGreen text-cWhite transition-all duration-300 hover:bg-cDarkGreen/80 text-bodyMSemibold"
                  >
                    Да, верно
                  </button>
                  <button
                    onClick={onClickNo}
                    className="pt-1 pb-1.4 px-2.3 rounded-[1.6rem] bg-cMediumGreyOnWhite text-bodyMSemibold text-cBlack transition-all duration-300 hover:bg-cMediumGreyOnWhite/60 "
                  >
                    Нет, другой
                  </button>
                </div>
              </div>
            </motion.div>
          </Container>
        </div>
      </Popup>
    </>
  );
};

export default CitySuggestionPopup;

const cvaScroll = cva([
  'PhonesPopup-cvaScroll',
  'p-0',
]);

const cvaPopup = cva([
  'PhonesPopup-cvaPopup',
  'align-bottom',
  'w-full',
  'bg-transparent',
]);

const cvaContainer = cva([
  'PhonesPopup-cvaContainer',
  'flex justify-start',
]);

const cvaBody = cva([
  'PhonesPopup-cvaBody',
  'relative bottom-2.4 left-0',
  'flex flex-col gap-2.4',
  'w-fit',
  'p-2.4 rounded-[1.6rem]',
  'shadow-popup bg-cWhite',
]);
